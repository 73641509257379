@import url('https://fonts.googleapis.com/css2?family=Vidaloka&display=swap');

html, body{
    /* height: 100%; */
    margin: 0;
    /* background-color: #EDF778; */
    /* background-color: #EDF778;  */
    
}
.contact-icons{
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 80px 80px 80px 80px;
    grid-template-rows: 80px;
    justify-items: center;
  }
.menu{
    color: white;
    font-size: 1.15em;
    margin-top: 20px;
  }
  .menu:hover p{
    text-decoration: underline;
    visibility: visible;
    font-size: 1em;
  }
  .menu:not(:hover) p{
    font-size: 1.15em;
    visibility: hidden;
  }
.nav{
    display: flex;
  }
.filler{
    position: absolute;
    color: #EDF778;
    font-family: 'Vidaloka', serif;
    top: 33%;
    width: 62%;
    font-weight: bolder;
    display: inline-grid;
    grid-template-columns: 75px 75px 20vh 75px 75px;

  }
  .filler>p{
    grid-column: 3;
    grid-row: 3/5;
    margin:0;
    display: inline-flex;
    font-size:44px;
    justify-content: center;
  }
  
.app2{
    border: hidden;
}

.nav2{
    position: absolute;
    top: 0px;
    left: 0px;
}

#container{
    height: 93%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
#left, #right{
    height: 100vh;
}

#right{
    background-color: white;
    display: flex; 
    justify-content: center;   
    position: absolute;
    width: 50%;
    right: 0;
    background-color: #EDF778;
}
#info{
    display:relative;
    top:20px;
}
#left{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center; 
    background-color: #215de9;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}
h1{
   font-size: 27px;
   line-height: 37px;
}
#join{
    font-size: 18px;
    line-height: 24px;
    margin: 16px auto;
}

/* div>a, ul>li {
    color:#aab8c2;
    padding-left: 263px;
  } */
  
  ul>li {
    color:#aab8c2;
    padding-left: 263px;
  }
#blue_text{
   max-width: 440px;
   padding: 16px;
   color: #EDF778;  
}
.heading{
    font-size: 20px;
    font-weight: bold;
}
 .paragraph{ 
    display: inline-block;
} 

a{
    color:#aab8c2;
    text-decoration: none;
}
.home {
    margin-top: 6px;
}
.home:hover p{
    text-decoration: underline;
    color: #EDF778;
    visibility: visible;
    font-size: 1rem;
}
.home:not(:hover) p{
    visibility: hidden;
}

#white_text{
    max-width: 350px;
    margin-top:15%;
 }


 #white_box{
    padding: 16px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 105px;
 }

 .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-top: -660px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .bm-burger-button{
    visibility: hidden;
  }
  
  @media only screen and (max-height: 660px){
 
    #white_text h1{
        font-size: 18px; 
     }
     #white_text h2{
         font-size: 16px; 
      }
}



@media screen and (max-height: 270px){
     #white_text{
        margin-top: -95px;
    }
}
@media only screen and (max-height: 660px) and (max-height: 500px){
    #white_text{
        margin-top: -184px;
    }
   
}
@media only screen and (max-width: 1024px){
 
    #white_text{
        position: relative;
        margin-top: 15%;
     }
     
}
   
@media only screen and (max-width: 1115px){
    .nav{
        visibility: hidden;
    }
    .bm-burger-button{
        visibility: visible;
    }
    
}

@media only screen and (max-width: 1115px) and (max-height: 500px) {
    .nav{
        visibility: hidden;
    }
    .bm-burger-button{
        visibility: visible;
    }
    #white_text{
        margin-top: -184px;
    }
    .small{
        font-size: 30px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;

      }
}

@media only screen and (max-width: 850px) {

    #container{
        display:flex;
        flex-direction: row;
        height: 100vh;
    }
    #right{
        position: absolute;
        /* min-height: 50vh; */
        min-height: 111%;
        width: 100%;
    }

    #white_text {
        position: relative;
        top: 45px;
        margin-top: 60%;
    }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
    margin-left: 0vw;
   
      }
      /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  img{
    width: 75%;
    height: 75%;
}
.contact-icons{
    margin-top: -38px;
  }
  .filler {
    /* position: re; */
    color: #EDF778;
    font-family: 'Vidaloka', serif;
    /* top: 30%; */
    top: 22%;
    width: 50%;
    font-weight: bolder;
    display: inline-grid;
    grid-template-columns: 25px 25px 30vh 25px 25px;
    }
    .filler>p{
    font-size: 36px;
    }
    
}

@media only screen and (max-width: 765px){
    .filler {
        position: relative;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 32%;
        width: 50%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 10vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }
}


/* @media only screen and (max-width: 797px){ */
    @media only screen and (max-width: 717px){
    #right{
        position: absolute;
        /* min-height: 50vh; */
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        margin-top: 70%;
        background-color: #EDF778;
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    img{
        width: 75%;
        height: 75%;
    }
    .contact-icons{
        margin-top: -38px;
      }
    .filler {
        position: relative;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 32%;
        width: 50%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 16vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }
}
@media only screen and (max-width: 768px){
    #right{
        position: absolute;
        /* min-height: 500vh; */
        /* height: 150%; */
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        background-color: #EDF778;
        /* top: 45px; */
        margin-top: 60%;
        
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    img{
        width: 75%;
        height: 75%;
    }
    .contact-icons{
        margin-top: -38px;
      }
    .filler {
        position: relative;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 32%;
        width: 50%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 18vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }  
    h1{
        font-size:22px;
    }
    .about>p{
        font-size: 14px;
    }
}

@media only screen and (min-width: 716px) and (max-width:767){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        /* margin-top: 78%; */
        background-color: #EDF778;
        top: 45px;
    margin-top: 70%;
        
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    img{
        width: 75%;
        height: 75%;
    }
    .contact-icons{
        margin-top: -38px;
      }
    .filler {
        position: relative;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 30%;
        width: 45%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 16vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }  
}
@media only screen and (max-width: 559px){
        #right{
            position: absolute;
            /* min-height: 50vh; */
            min-height: 120vh;
            width: 100%;
            background-color: #EDF778;
        }
        #white_text{
            position: relative;
            margin-top: 65%;
            background-color: #EDF778;
         }
        #left{
            position: absolute;
            height: 50vh;
            width: 100%;
        }
        #blue_text{
            position: relative;
                margin-top: -10%;
                color: #EDF778;
        }
        .switch {
            margin-top: 0vh;
            margin-left: 0vw;
        }
        img{
            width: 75%;
            height: 75%;
        }
        .contact-icons{
            margin-top: -38px;
          }
}
    @media only screen and (max-width: 557px) and (max-height: 500px){
        #right{
            display: block;
            background-color: #EDF778;
            position: absolute;
            margin-top: 264px;  
        }
        #left{
            height: 264px;  
            display: block;
            width: 100vw;
        }
     
        #white_text{
            font-size: .2em;
            height: 100%;
            left: 188px;
            margin-top: 82%;
        }
       
        #white_text h1{
           font-size: 18px; 
        }
        #white_text h2{
            font-size: 16px; 
         }
         #blue_text h2, #blue_text p{
            visibility: hidden;
         }
         .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
          }
          .filler {
            position: absolute;
            color: #EDF778;
            font-family: 'Vidaloka', serif;
            top: 30%;
            width: 50%;
            font-weight: bolder;
            display: inline-grid;
            grid-template-columns: 25px 25px 30vh 25px 25px;
            }
        .filler>p{
            font-size: 36px;
            }
        .icon {
            font-size: 90px;
            }
            h1{
            font-size: 29px;
            line-height: 30px;
            }
        .about p{
            font-size: 16px;
            }      
}

@media only screen and (max-width: 414px)and (max-height: 719px){
    #right{
        position: absolute;
        height: 50vh;
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        margin-top: 75%;
        background-color: #EDF778;
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    img{
        width: 90%;
        height: 90%;
    }
    .contact-icons{
        margin-top: 10px;
      }
    .filler {
        position: absolute;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 30%;
        width: 50%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 30vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }
    .icon {
        font-size: 90px;
        }
        h1{
        font-size: 27px;
        line-height: 25px;
        }
    .about p{
        font-size: 14px;
        }
    }
        .skills{
            margin-left: -7%;
        }
@media only screen and (max-width: 411px) and (max-height: 823px){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        margin-top: 100%;
        background-color: #EDF778;
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    .filler {
        position: absolute;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 30%;
        width: 50%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 30vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }
    .icon {
        font-size: 90px;
        }
        h1{
        font-size: 29px;
        line-height: 30px;
        }
    .about p{
        font-size: 16px;
        }
}

@media only screen and (max-width: 411px) and (max-height: 731px){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        margin-top: 81%;
        background-color: #EDF778;
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    
}

@media only screen and (max-width: 375px){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
        overflow: auto;
    }
    #white_text{
        position: relative;
        margin-top: 100%;
        background-color: #EDF778;
        
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    
}

@media only screen and (max-width: 375px) and (max-height: 667px){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
        overflow: auto;
    }
    
    #white_text{
        position: relative;
        margin-top: 68%;
        background-color: #EDF778;
        
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    
}
@media only screen and (max-width: 360px){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
        overflow: auto;
    }
    #white_text{
        position: relative;
        margin-top: 67%;
        background-color: #EDF778;
        
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    
}
@media only screen and (max-width: 375px) and (max-height: 635px){
    #right{
        /* position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
        overflow: auto; */
        position: absolute;
        /* height: 50vh; */
        /* min-height: 0vh; */
        width: 100%;
        /* background-color: #EDF778; */
        /* overflow: auto; */
        bottom: 0; 
    }
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        overflow: inherit;
      background-color: #EDF778; 
    }
    #white_box {
        /* padding: 16px; */
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 90px;
        /* margin-top: 0%;  */
        
    }
    #white_text{
        /* position: relative; */
        /* position: relative;
        /* margin-top: 68%; */
        margin-top: 0%; 
        min-width: 100vw;
        position: absolute;
        
        /* background-color: #EDF778; */
     }
     #info{
        position: relative;
         top:0px
     }
     .skills {
        margin-left: -2.5%;
    }
}