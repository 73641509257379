.App {
    text-align: center;
    background-color: #61dafb;
    
  
    /* border-image: url("./images/cheetahprint.jpg") 30 round; */
  }
  /* import */
  
  #root{
    background-color: white;
    min-height: 100vh;
    text-align: center;
  }
  .app{
   /* border: 1.5em solid ; */
  /* border-image: url("../images/cheetahprint.jpg") 100 round ;    */
    min-height: 95vh;
  }
  
  
  /* #blackLogo {
    height: 30%;
    width: 30%;
    position: relative;
   
  top: 5%; 
  left: 35%; 
 
  } */

#content{
  display: flex;
  flex-direction: column;
}

.about{
  width: 100%;
}

  .twitter{
    height: 200px;
  }
 