@import url(https://fonts.googleapis.com/css2?family=Vidaloka&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Vidaloka&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* .nav{
  padding: 35px;
} */

.projects{
  display: grid;
    grid-template-rows: 10% auto;
}
.cards{
      /* display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;
      width:100%; */
      display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    justify-content: space-evenly;
    grid-row-gap: 10px;
    row-gap: 10px; 
    grid-column-gap: 10px; 
    -webkit-column-gap: 10px; 
            column-gap: 10px;
  }
  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    /* width: 30%; */
    width: 450px;
    height: 500px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  .card>img{
    /* width: 100%;
    height: 100%; */
    /* width:300px; */
    width: 100%;
    height: 300px;
    border-style: inset;
    /* border-color: green; */
    border-bottom-color: black;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
  }
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */
  .container {
    padding: 2px 16px;
  }
  img{
      width: 100%;
      height: 100%;
      border-style: inset;
      border-color: transparent; 
      border-bottom: violet;
  }
  

  .btn{
    color: black;
    padding-left: 0px;
  }
  .btn:hover{
    text-decoration: none;
    padding-left: 0px;
  }
  button>a{
    color: black;
  }
  .BackToTop-project{
    color: #215de9;
  
}
/* .link{
  padding: 35px;
}
.page-link>a:hover{
  color: black;  
} 
.page-link>a>p:hover{
  border-bottom: 2px solid black;
  padding-bottom: 8px;
} */

  @media only screen and (max-width: 1286px) {
    /* .card { */
        /* width: 350px; */
        /* height:100%; */
        /* background-color: yellow; */
    /* } */
    /* .cards{
        justify-content: space-around;
    } */
  }
  /* @media only screen and (max-width: 600px) {
    .card {
        width: 70%;
        height:100%;
        background-color: teal;
    }
  } */

   @media only screen and (max-width: 450px) {
    .projects{
      display: grid;
      grid-template-rows: 5% 95%;
        
    }
    .card {
      width: 70%;
      /* height:70%; */
      /* background-color: teal; */
      margin-left: 10%;
        margin-right: 10%;
    }
    .BackToTop-project {
      position: relative;
      left: 55px;
  }
   /* .cards {
      /* overflow-x: hidden; */
      /* margin-left: auto;
      margin-right: auto; */
    /* } */ 
  }
/* .nav:checked {
    margin-left: 250px;
    border: 20px solid blue;
  } */
  /* .nav > *:checked{
    color: blue;
    font-size: xx-large;
  } */
 /* .small_div{
    grid-column: 2;
    visibility: initial;
} */
/* .nav{
  /* display: grid; */
  /* grid-template-columns: 50% 50%; */
  /* visibility: initial; */
/* } */ 


.App {
    text-align: center;
    background-color: #61dafb;
    
  
    /* border-image: url("./images/cheetahprint.jpg") 30 round; */
  }
  /* import */
  
  #root{
    background-color: white;
    min-height: 100vh;
    text-align: center;
  }
  .app{
   /* border: 1.5em solid ; */
  /* border-image: url("../images/cheetahprint.jpg") 100 round ;    */
    min-height: 95vh;
  }
  
  
  /* #blackLogo {
    height: 30%;
    width: 30%;
    position: relative;
   
  top: 5%; 
  left: 35%; 
 
  } */

#content{
  display: flex;
  flex-direction: column;
}

.about{
  width: 100%;
}

  .twitter{
    height: 200px;
  }
 
html, body{
    /* height: 100%; */
    margin: 0;
    /* background-color: #EDF778; */
    /* background-color: #EDF778;  */
    
}
.contact-icons{
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 80px 80px 80px 80px;
    grid-template-rows: 80px;
    justify-items: center;
  }
.menu{
    color: white;
    font-size: 1.15em;
    margin-top: 20px;
  }
  .menu:hover p{
    text-decoration: underline;
    visibility: visible;
    font-size: 1em;
  }
  .menu:not(:hover) p{
    font-size: 1.15em;
    visibility: hidden;
  }
.nav{
    display: flex;
  }
.filler{
    position: absolute;
    color: #EDF778;
    font-family: 'Vidaloka', serif;
    top: 33%;
    width: 62%;
    font-weight: bolder;
    display: inline-grid;
    grid-template-columns: 75px 75px 20vh 75px 75px;

  }
  .filler>p{
    grid-column: 3;
    grid-row: 3/5;
    margin:0;
    display: inline-flex;
    font-size:44px;
    justify-content: center;
  }
  
.app2{
    border: hidden;
}

.nav2{
    position: absolute;
    top: 0px;
    left: 0px;
}

#container{
    height: 93%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
#left, #right{
    height: 100vh;
}

#right{
    background-color: white;
    display: flex; 
    justify-content: center;   
    position: absolute;
    width: 50%;
    right: 0;
    background-color: #EDF778;
}
#info{
    display:relative;
    top:20px;
}
#left{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center; 
    background-color: #215de9;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}
h1{
   font-size: 27px;
   line-height: 37px;
}
#join{
    font-size: 18px;
    line-height: 24px;
    margin: 16px auto;
}

/* div>a, ul>li {
    color:#aab8c2;
    padding-left: 263px;
  } */
  
  ul>li {
    color:#aab8c2;
    padding-left: 263px;
  }
#blue_text{
   max-width: 440px;
   padding: 16px;
   color: #EDF778;  
}
.heading{
    font-size: 20px;
    font-weight: bold;
}
 .paragraph{ 
    display: inline-block;
} 

a{
    color:#aab8c2;
    text-decoration: none;
}
.home {
    margin-top: 6px;
}
.home:hover p{
    text-decoration: underline;
    color: #EDF778;
    visibility: visible;
    font-size: 1rem;
}
.home:not(:hover) p{
    visibility: hidden;
}

#white_text{
    max-width: 350px;
    margin-top:15%;
 }


 #white_box{
    padding: 16px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 105px;
 }

 .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-top: -660px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .bm-burger-button{
    visibility: hidden;
  }
  
  @media only screen and (max-height: 660px){
 
    #white_text h1{
        font-size: 18px; 
     }
     #white_text h2{
         font-size: 16px; 
      }
}



@media screen and (max-height: 270px){
     #white_text{
        margin-top: -95px;
    }
}
@media only screen and (max-height: 660px) and (max-height: 500px){
    #white_text{
        margin-top: -184px;
    }
   
}
@media only screen and (max-width: 1024px){
 
    #white_text{
        position: relative;
        margin-top: 15%;
     }
     
}
   
@media only screen and (max-width: 1115px){
    .nav{
        visibility: hidden;
    }
    .bm-burger-button{
        visibility: visible;
    }
    
}

@media only screen and (max-width: 1115px) and (max-height: 500px) {
    .nav{
        visibility: hidden;
    }
    .bm-burger-button{
        visibility: visible;
    }
    #white_text{
        margin-top: -184px;
    }
    .small{
        font-size: 30px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;

      }
}

@media only screen and (max-width: 850px) {

    #container{
        display:flex;
        flex-direction: row;
        height: 100vh;
    }
    #right{
        position: absolute;
        /* min-height: 50vh; */
        min-height: 111%;
        width: 100%;
    }

    #white_text {
        position: relative;
        top: 45px;
        margin-top: 60%;
    }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
    margin-left: 0vw;
   
      }
      /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  img{
    width: 75%;
    height: 75%;
}
.contact-icons{
    margin-top: -38px;
  }
  .filler {
    /* position: re; */
    color: #EDF778;
    font-family: 'Vidaloka', serif;
    /* top: 30%; */
    top: 22%;
    width: 50%;
    font-weight: bolder;
    display: inline-grid;
    grid-template-columns: 25px 25px 30vh 25px 25px;
    }
    .filler>p{
    font-size: 36px;
    }
    
}

@media only screen and (max-width: 765px){
    .filler {
        position: relative;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 32%;
        width: 50%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 10vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }
}


/* @media only screen and (max-width: 797px){ */
    @media only screen and (max-width: 717px){
    #right{
        position: absolute;
        /* min-height: 50vh; */
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        margin-top: 70%;
        background-color: #EDF778;
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    img{
        width: 75%;
        height: 75%;
    }
    .contact-icons{
        margin-top: -38px;
      }
    .filler {
        position: relative;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 32%;
        width: 50%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 16vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }
}
@media only screen and (max-width: 768px){
    #right{
        position: absolute;
        /* min-height: 500vh; */
        /* height: 150%; */
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        background-color: #EDF778;
        /* top: 45px; */
        margin-top: 60%;
        
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    img{
        width: 75%;
        height: 75%;
    }
    .contact-icons{
        margin-top: -38px;
      }
    .filler {
        position: relative;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 32%;
        width: 50%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 18vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }  
    h1{
        font-size:22px;
    }
    .about>p{
        font-size: 14px;
    }
}

@media only screen and (min-width: 716px) and (max-width:767){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        /* margin-top: 78%; */
        background-color: #EDF778;
        top: 45px;
    margin-top: 70%;
        
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    img{
        width: 75%;
        height: 75%;
    }
    .contact-icons{
        margin-top: -38px;
      }
    .filler {
        position: relative;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 30%;
        width: 45%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 16vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }  
}
@media only screen and (max-width: 559px){
        #right{
            position: absolute;
            /* min-height: 50vh; */
            min-height: 120vh;
            width: 100%;
            background-color: #EDF778;
        }
        #white_text{
            position: relative;
            margin-top: 65%;
            background-color: #EDF778;
         }
        #left{
            position: absolute;
            height: 50vh;
            width: 100%;
        }
        #blue_text{
            position: relative;
                margin-top: -10%;
                color: #EDF778;
        }
        .switch {
            margin-top: 0vh;
            margin-left: 0vw;
        }
        img{
            width: 75%;
            height: 75%;
        }
        .contact-icons{
            margin-top: -38px;
          }
}
    @media only screen and (max-width: 557px) and (max-height: 500px){
        #right{
            display: block;
            background-color: #EDF778;
            position: absolute;
            margin-top: 264px;  
        }
        #left{
            height: 264px;  
            display: block;
            width: 100vw;
        }
     
        #white_text{
            font-size: .2em;
            height: 100%;
            left: 188px;
            margin-top: 82%;
        }
       
        #white_text h1{
           font-size: 18px; 
        }
        #white_text h2{
            font-size: 16px; 
         }
         #blue_text h2, #blue_text p{
            visibility: hidden;
         }
         .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
          }
          .filler {
            position: absolute;
            color: #EDF778;
            font-family: 'Vidaloka', serif;
            top: 30%;
            width: 50%;
            font-weight: bolder;
            display: inline-grid;
            grid-template-columns: 25px 25px 30vh 25px 25px;
            }
        .filler>p{
            font-size: 36px;
            }
        .icon {
            font-size: 90px;
            }
            h1{
            font-size: 29px;
            line-height: 30px;
            }
        .about p{
            font-size: 16px;
            }      
}

@media only screen and (max-width: 414px)and (max-height: 719px){
    #right{
        position: absolute;
        height: 50vh;
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        margin-top: 75%;
        background-color: #EDF778;
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    img{
        width: 90%;
        height: 90%;
    }
    .contact-icons{
        margin-top: 10px;
      }
    .filler {
        position: absolute;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 30%;
        width: 50%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 30vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }
    .icon {
        font-size: 90px;
        }
        h1{
        font-size: 27px;
        line-height: 25px;
        }
    .about p{
        font-size: 14px;
        }
    }
        .skills{
            margin-left: -7%;
        }
@media only screen and (max-width: 411px) and (max-height: 823px){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        margin-top: 100%;
        background-color: #EDF778;
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    .filler {
        position: absolute;
        color: #EDF778;
        font-family: 'Vidaloka', serif;
        top: 30%;
        width: 50%;
        font-weight: bolder;
        display: inline-grid;
        grid-template-columns: 25px 25px 30vh 25px 25px;
        }
    .filler>p{
        font-size: 36px;
        }
    .icon {
        font-size: 90px;
        }
        h1{
        font-size: 29px;
        line-height: 30px;
        }
    .about p{
        font-size: 16px;
        }
}

@media only screen and (max-width: 411px) and (max-height: 731px){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
    }
    #white_text{
        position: relative;
        margin-top: 81%;
        background-color: #EDF778;
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    
}

@media only screen and (max-width: 375px){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
        overflow: auto;
    }
    #white_text{
        position: relative;
        margin-top: 100%;
        background-color: #EDF778;
        
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    
}

@media only screen and (max-width: 375px) and (max-height: 667px){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
        overflow: auto;
    }
    
    #white_text{
        position: relative;
        margin-top: 68%;
        background-color: #EDF778;
        
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    
}
@media only screen and (max-width: 360px){
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
        overflow: auto;
    }
    #white_text{
        position: relative;
        margin-top: 67%;
        background-color: #EDF778;
        
     }
    #left{
        position: absolute;
        height: 50vh;
        width: 100%;
    }
    #blue_text{
        position: relative;
            margin-top: 0px;
            color: #EDF778;
    }
    .switch {
        margin-top: 0vh;
        margin-left: 0vw;
    }
    
}
@media only screen and (max-width: 375px) and (max-height: 635px){
    #right{
        /* position: absolute;
        min-height: 50vh;
        width: 100%;
        background-color: #EDF778;
        overflow: auto; */
        position: absolute;
        /* height: 50vh; */
        /* min-height: 0vh; */
        width: 100%;
        /* background-color: #EDF778; */
        /* overflow: auto; */
        bottom: 0; 
    }
    #right{
        position: absolute;
        min-height: 50vh;
        width: 100%;
        overflow: inherit;
      background-color: #EDF778; 
    }
    #white_box {
        /* padding: 16px; */
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 90px;
        /* margin-top: 0%;  */
        
    }
    #white_text{
        /* position: relative; */
        /* position: relative;
        /* margin-top: 68%; */
        margin-top: 0%; 
        min-width: 100vw;
        position: absolute;
        
        /* background-color: #EDF778; */
     }
     #info{
        position: relative;
         top:0px
     }
     .skills {
        margin-left: -2.5%;
    }
}

.thread{
    /* background-color: rgb(32, 92, 233); */
    display: grid;
    grid-template-rows:5% auto;
}
.Tweets{
    margin-left: 20%;
    margin-right: 20%;
}
.BackToTop-thread{
    color: #215de9;
}
.nav{
    display: grid;
    grid-template-columns: 50% 50%;
    /* visibility: initial; */
  }
  .blackLogo {
    width: 187.5px;
    height: 109.94px;
    position: absolute;
    right: 30px;
}
.small_div{
    grid-column: 2;
    visibility: visible;
    visibility: initial;
}

@media only screen and (max-width: 559px) {
.blackLogo{
    width: 140.5px;
    height: 87.94px;
}
}
@media only screen and (max-width: 450px) {
    .thread{
        /* background-color: rgb(32, 92, 233); */
        display: grid;
        grid-template-rows: 5% 95%;
    }
    
    .Tweets{
        margin-left: 20%;
        margin-right: 20%;
    }
    
    .BackToTop-thread {
        position: relative;
        left: 50px;
    }
  
  }

@media only screen and (max-width: 414px) {
    .blackLogo {
        /* height: 15%;
        width: 20%; */
        width: 140.5px;
    height: 87.94px;
        position: absolute;
        right: 30px;
        top: 0px
    }
}
html,
body {
  margin: 0;
}
.sidebar{
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 70px 70px 70px 70px;
  position: absolute;
  left: 5px;
  color: white;
}
.sidebarIcon:not(hover){
  color: white;
}
.sidebar:hover label{
  /* font-size: 12px; */
  font-size: .55em;
  color: white;
}
#App {
  font-family: sans-serif;

  /* Give app full page to work with */
  height: 100vh;
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  /* display: inline-block; */

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.app {
  text-align: center;
  background-color: white;
 /* border: 1.5em solid ; */
  /* border-image: url("./images/cheetahprint.png") 100 round ; */
  /* border-image: url("./images/cheetahprint.jpg") 30 round; */
}
/* import */
html, body{
  background-color: white;

}
#root{
  /* background-color: white; */
  min-height: 100vh;
  text-align: center;
  /* border: 1.5em solid ;
  border-image: url("./images/cheetahprint.png") 100 round ; */
  
}

label>p{
  margin: 0;
}

.link{
  padding: 35px;
}
.page-link>a:hover{
  color: black;  
} 
.page-link>a>p:hover{
  border-bottom: 2px solid black;
  padding-bottom: 8px;
}

.crown{
  height: 80px;
  width: 80px;
  margin-right: -64px;
  margin-bottom: -50px;
  z-index: 1;
}

.nav2>a{
  color: black;
  font-family: 'Vidaloka', serif;
  font-weight: 700;
  font-size: x-large;
  /* margin-left: 15px; */
}
.main-nav2>a:hover{
    /* text-decoration: underline; */
    color: #EDF778;  
    font-family: 'Vidaloka', serif;
} 


.icon{
  font-size: 60px;
  color: black;
  
}
.contact{
  font-size: 35px;
  padding-left: 0px;
  color: #EDF778;
  font-family: 'Vidaloka', serif;
  margin-left: 0px;
}
.container{
  background-color: white;
}
button>a{
  color: black;
}

/* .skills{
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-evenly;
} */
.skills{
display: grid;
flex-wrap: wrap;
grid-template-columns: 100px 100px 100px 100px;
grid-template-rows: 80px 80px;
grid-row-gap: 5px;
justify-items: center;
}

/* .blogo{
  justify-content: center;
} */

  /* .blackLogo {
    height: 15%;
    width: 15%;
    position: absolute;
    /* margin-bottom: 0px; */
    /* left: 30px; */
    /* position: fixed; */
    /* top: 5%; */
    /* right: 39%; */

/* position: relative;  */

/* left: 30px; */
/* mar */
/* position: fixed; */
/* top: 5%;  */
/* left: 35%;  */
/* } */ 

/* .menu{
  color: white;
  font-size: 1.15em;
  margin-top: 20px;
}
.menu:hover p{
  text-decoration: underline;
  visibility: visible;
  font-size: 1em;
}
.menu:not(:hover) p{
  font-size: 1.15em;
  visibility: hidden;
} */

.main{
  font-size: 120%;
  margin-top: 44px;
  color: black;
}
.main:hover{
  color: #215de9;
}
.main:hover p{
  text-decoration: underline;
  color: rgb(33, 93, 233);
  visibility: visible;
  font-size: 1rem;
}
.main:not(:hover) p{
  visibility: hidden;
}

.skill, h4{
  font-family: 'Vidaloka', serif;
}
.skill:hover p{
  visibility: visible;
  font-size: 1rem;
  margin-bottom: 5px;
  font-weight: bolder;
}
.skill:not(:hover) p{
  visibility: hidden;
}

.nav2>a:hover .crown {
  visibility: visible;
}
.nav2>a:not(:hover) .crown {
  visibility: hidden;
}

.nav2>a>p{
  display: inline-block;
}
.main-nav2>a>p:hover{
  border-bottom: 2px solid #EDF778;
  padding-bottom: 8px;
}

/* .contact-icons{
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 80px 80px 80px 80px;
  grid-template-rows: 80px;
  justify-items: center;
} */
/* .main{
  display: inline-flex;
  flex-wrap: wrap;
  align-content: space-evenly;
  position: relative;
  left: .75%;

 }   */

.new-nav{
  position: absolute;
  left: 40%;
  width: 700px;
}
.new-nav>a{
  color: black;
  font-family: 'Vidaloka', serif;
  font-weight: 700;
  font-size: x-large;
  margin-left: 5px;
}
/* .new-nav>a:hover{
    text-decoration: underline;
    color: #EDF778;
} */
/* 
.link{
  position: absolute;
  top: 80px;
  left: 0;
  right: 206px;
} */
.new-nav>a{
  margin-left: 60px;
  margin-right: 60px;
}

.timeline-Widget {
  /* max-width: 1200px; */
  background-color: #fff;
  border-radius: 5px;
}

/* @media only screen and (max-width: 559px){
  .blackLogo{
  height: 40%;
  width: 40%;
  position: relative; 
  margin-bottom: 0px;
  
  }
} */

