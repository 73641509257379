@import url('https://fonts.googleapis.com/css2?family=Vidaloka&display=swap');

.app {
  text-align: center;
  background-color: white;
 /* border: 1.5em solid ; */
  /* border-image: url("./images/cheetahprint.png") 100 round ; */
  /* border-image: url("./images/cheetahprint.jpg") 30 round; */
}
/* import */
html, body{
  background-color: white;

}
#root{
  /* background-color: white; */
  min-height: 100vh;
  text-align: center;
  /* border: 1.5em solid ;
  border-image: url("./images/cheetahprint.png") 100 round ; */
  
}

label>p{
  margin: 0;
}

.link{
  padding: 35px;
}
.page-link>a:hover{
  color: black;  
} 
.page-link>a>p:hover{
  border-bottom: 2px solid black;
  padding-bottom: 8px;
}

.crown{
  height: 80px;
  width: 80px;
  margin-right: -64px;
  margin-bottom: -50px;
  z-index: 1;
}

.nav2>a{
  color: black;
  font-family: 'Vidaloka', serif;
  font-weight: 700;
  font-size: x-large;
  /* margin-left: 15px; */
}
.main-nav2>a:hover{
    /* text-decoration: underline; */
    color: #EDF778;  
    font-family: 'Vidaloka', serif;
} 


.icon{
  font-size: 60px;
  color: black;
  
}
.contact{
  font-size: 35px;
  padding-left: 0px;
  color: #EDF778;
  font-family: 'Vidaloka', serif;
  margin-left: 0px;
}
.container{
  background-color: white;
}
button>a{
  color: black;
}

/* .skills{
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-evenly;
} */
.skills{
display: grid;
flex-wrap: wrap;
grid-template-columns: 100px 100px 100px 100px;
grid-template-rows: 80px 80px;
grid-row-gap: 5px;
justify-items: center;
}

/* .blogo{
  justify-content: center;
} */

  /* .blackLogo {
    height: 15%;
    width: 15%;
    position: absolute;
    /* margin-bottom: 0px; */
    /* left: 30px; */
    /* position: fixed; */
    /* top: 5%; */
    /* right: 39%; */

/* position: relative;  */

/* left: 30px; */
/* mar */
/* position: fixed; */
/* top: 5%;  */
/* left: 35%;  */
/* } */ 

/* .menu{
  color: white;
  font-size: 1.15em;
  margin-top: 20px;
}
.menu:hover p{
  text-decoration: underline;
  visibility: visible;
  font-size: 1em;
}
.menu:not(:hover) p{
  font-size: 1.15em;
  visibility: hidden;
} */

.main{
  font-size: 120%;
  margin-top: 44px;
  color: black;
}
.main:hover{
  color: #215de9;
}
.main:hover p{
  text-decoration: underline;
  color: rgb(33, 93, 233);
  visibility: visible;
  font-size: 1rem;
}
.main:not(:hover) p{
  visibility: hidden;
}

.skill, h4{
  font-family: 'Vidaloka', serif;
}
.skill:hover p{
  visibility: visible;
  font-size: 1rem;
  margin-bottom: 5px;
  font-weight: bolder;
}
.skill:not(:hover) p{
  visibility: hidden;
}

.nav2>a:hover .crown {
  visibility: visible;
}
.nav2>a:not(:hover) .crown {
  visibility: hidden;
}

.nav2>a>p{
  display: inline-block;
}
.main-nav2>a>p:hover{
  border-bottom: 2px solid #EDF778;
  padding-bottom: 8px;
}

/* .contact-icons{
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 80px 80px 80px 80px;
  grid-template-rows: 80px;
  justify-items: center;
} */
/* .main{
  display: inline-flex;
  flex-wrap: wrap;
  align-content: space-evenly;
  position: relative;
  left: .75%;

 }   */

.new-nav{
  position: absolute;
  left: 40%;
  width: 700px;
}
.new-nav>a{
  color: black;
  font-family: 'Vidaloka', serif;
  font-weight: 700;
  font-size: x-large;
  margin-left: 5px;
}
/* .new-nav>a:hover{
    text-decoration: underline;
    color: #EDF778;
} */
/* 
.link{
  position: absolute;
  top: 80px;
  left: 0;
  right: 206px;
} */
.new-nav>a{
  margin-left: 60px;
  margin-right: 60px;
}

.timeline-Widget {
  /* max-width: 1200px; */
  background-color: #fff;
  border-radius: 5px;
}

/* @media only screen and (max-width: 559px){
  .blackLogo{
  height: 40%;
  width: 40%;
  position: relative; 
  margin-bottom: 0px;
  
  }
} */
