
/* .nav{
  padding: 35px;
} */

.projects{
  display: grid;
    grid-template-rows: 10% auto;
}
.cards{
      /* display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;
      width:100%; */
      display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    justify-content: space-evenly;
    row-gap: 10px; 
    column-gap: 10px;
  }
  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    /* width: 30%; */
    width: 450px;
    height: 500px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  .card>img{
    /* width: 100%;
    height: 100%; */
    /* width:300px; */
    width: 100%;
    height: 300px;
    border-style: inset;
    /* border-color: green; */
    border-bottom-color: black;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
  }
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */
  .container {
    padding: 2px 16px;
  }
  img{
      width: 100%;
      height: 100%;
      border-style: inset;
      border-color: transparent; 
      border-bottom: violet;
  }
  

  .btn{
    color: black;
    padding-left: 0px;
  }
  .btn:hover{
    text-decoration: none;
    padding-left: 0px;
  }
  button>a{
    color: black;
  }
  .BackToTop-project{
    color: #215de9;
  
}
/* .link{
  padding: 35px;
}
.page-link>a:hover{
  color: black;  
} 
.page-link>a>p:hover{
  border-bottom: 2px solid black;
  padding-bottom: 8px;
} */

  @media only screen and (max-width: 1286px) {
    /* .card { */
        /* width: 350px; */
        /* height:100%; */
        /* background-color: yellow; */
    /* } */
    /* .cards{
        justify-content: space-around;
    } */
  }
  /* @media only screen and (max-width: 600px) {
    .card {
        width: 70%;
        height:100%;
        background-color: teal;
    }
  } */

   @media only screen and (max-width: 450px) {
    .projects{
      display: grid;
      grid-template-rows: 5% 95%;
        
    }
    .card {
      width: 70%;
      /* height:70%; */
      /* background-color: teal; */
      margin-left: 10%;
        margin-right: 10%;
    }
    .BackToTop-project {
      position: relative;
      left: 55px;
  }
   /* .cards {
      /* overflow-x: hidden; */
      /* margin-left: auto;
      margin-right: auto; */
    /* } */ 
  }