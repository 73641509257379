
.thread{
    /* background-color: rgb(32, 92, 233); */
    display: grid;
    grid-template-rows:5% auto;
}
.Tweets{
    margin-left: 20%;
    margin-right: 20%;
}
.BackToTop-thread{
    color: #215de9;
}
.nav{
    display: grid;
    grid-template-columns: 50% 50%;
    /* visibility: initial; */
  }
  .blackLogo {
    width: 187.5px;
    height: 109.94px;
    position: absolute;
    right: 30px;
}
.small_div{
    grid-column: 2;
    visibility: initial;
}

@media only screen and (max-width: 559px) {
.blackLogo{
    width: 140.5px;
    height: 87.94px;
}
}
@media only screen and (max-width: 450px) {
    .thread{
        /* background-color: rgb(32, 92, 233); */
        display: grid;
        grid-template-rows: 5% 95%;
    }
    
    .Tweets{
        margin-left: 20%;
        margin-right: 20%;
    }
    
    .BackToTop-thread {
        position: relative;
        left: 50px;
    }
  
  }

@media only screen and (max-width: 414px) {
    .blackLogo {
        /* height: 15%;
        width: 20%; */
        width: 140.5px;
    height: 87.94px;
        position: absolute;
        right: 30px;
        top: 0px
    }
}